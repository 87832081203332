<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="send-address">
          <el-divider content-position="left" class="title">添加发件人</el-divider>
          <!-- 添加地址input -->
          <div class="inputs">
            <el-form :inline="true" :model="formData">
              <el-form-item>
                <el-input v-model="formData.send_name" type="text" placeholder="姓名" style="width: 100px;"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="formData.send_phone" type="text" placeholder="手机号" style="width: 150px;"></el-input>
              </el-form-item>
              <el-form-item>
                <el-cascader
                  size="large"
                  :options="options"
                  v-model="selectedOptions"
                  @change="handleChange">
                </el-cascader>
              </el-form-item>
              <el-form-item>
                <el-input v-model="formData.send_address" type="text" placeholder="详细地址，街道，楼栋等" style="width: 280px;"></el-input>
              </el-form-item>
            </el-form>
            <el-button @click="addAddress" type="primary" size="small">确认添加</el-button>
          </div>
        </div>
        <!-- 地址列表展示 -->
        <div class="list">
          <el-divider content-position="left" class="title">发件人列表</el-divider>
          <!-- 列表 -->
          <div class="list-table">
            <el-table
              :data="areaList.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
              :header-cell-style="{background:'#fafafa'}"
              style="width: 100%">
              <el-table-column
                label="序号"
                min-width="20%">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.$index+(currentPage - 1) * pageSize + 1}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="收货人姓名"
                min-width="20%"
                >
              </el-table-column>
              <el-table-column
                prop="send_phone"
                label="手机号"
                min-width="20%">
              </el-table-column>
              <el-table-column
                label="收货地址"
                min-width="20%">
                <template slot-scope="scope">
                  <div class="details">
<!--                    <i v-if="scope.row.status === '1'" class="el-icon-s-home">默认地址</i>-->
                    <span>{{scope.row.details}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="默认状态" min-width="20%">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#eeeeee" disabled active-value="1" inactive-value="0" >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                min-width="10%">
                <template slot-scope="scope">
                  <div class="operations">
                    <span v-if="scope.row.status !== '1'" @click="setDefault(scope.row.id)">设为默认</span>
                    <span @click="deleteClick(scope.row.id)">删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="list-footer">
            <div>一共{{areaList.length}}条数据</div>
            <el-pagination
              @current-change="handleCurrentChange"
              background
              class="addressListPage"
              layout="prev, pager, next"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="areaList.length">
            </el-pagination>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { addArea, getAreaList, areaDel, homeArea } from 'network/api'
import { regionData, CodeToText } from 'element-china-area-data'

export default {
  inject: ['mainReload'],
  components: {
    AppMain
  },
  data () {
    return {
      options: regionData,
      selectedOptions: [],
      formData: {
        send_name: '',
        send_phone: '',
        send_province: '',
        send_city: '',
        send_town: '',
        send_address: ''
      },
      // 地址列表
      areaList: [],
      currentPage: 1, // 当前页
      pageSize: 10 // 每页多少条
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 点击添加地址
    addAddress () {
      for (const key in this.formData) {
        // 判断值是否为空
        if (!this.formData[key]) {
          this.$message({
            message: '请完善信息',
            type: 'warning'
          })
          return false
        }
      }
      // 验证手机号
      if (!(/^1[3|4|5|7|8][0-9]{9}$/.test(this.formData.send_phone))) {
        this.$message({
          message: '手机号码错误',
          type: 'warning'
        })
      } else {
        // 正确则添加地址
        addArea(this.formData).then(res => {
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
          }
        })
        this.mainReload() // 更新列表
      }
    },
    // 得到发件人地址
    getList () {
      getAreaList().then(res => {
        this.areaList = res.data
        // 遍历拼接地址
        for (let i = 0; i < res.data.length; i++) {
          this.areaList[i].details = res.data[i].send_province + res.data[i].send_city + res.data[i].send_town + res.data[i].send_address
        }
      })
    },
    // 当前页
    handleCurrentChange (val) {
      this.currentPage = val
    },
    // 删除
    deleteClick (id) {
      areaDel(id).then(res => {
        if (res.code === 1) {
          this.getList()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message(res.msg)
        }
      })
    },
    // 设为默认
    setDefault (id) {
      homeArea(id).then(res => {
        if (res.code === 1) {
          this.getList()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message(res.msg)
        }
      })
    },
    // 三级联动回调
    handleChange (val) {
      // console.log(val)
      this.formData.send_province = CodeToText[val[0]]
      this.formData.send_city = CodeToText[val[1]]
      this.formData.send_town = CodeToText[val[2]]
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/sendAddress.css">
</style>
